<script setup>
  const handleShareButton = async () => {
    if (document && window && navigator && navigator.share) {
      await navigator.share({ title: document.title, text: document.title, url: window.location.href });
    }
  }
</script>

<template>
  <button @click="handleShareButton"><i class="fa-solid fa-arrow-up-from-bracket fa-xl"></i></button>
</template>